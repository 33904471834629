export const dataCountryCode = [
  { id: "MP", value: 0 },
  { id: "ZA", value: 0 },
  { id: "ES", value: 0 },
  { id: "VG", value: 0 },
  { id: "NR", value: 0 },
  { id: "CA", value: 0 },
  { id: "MS", value: 0 },
  { id: "BQ", value: 0 },
  { id: "PM", value: 0 },
  { id: "PG", value: 0 },
  { id: "TC", value: 0 },
  { id: "JE", value: 0 },
  { id: "SM", value: 0 },
  { id: "GB", value: 0 },
  { id: "GD", value: 0 },
  { id: "GU", value: 0 },
  { id: "LU", value: 0 },
  { id: "IE", value: 0 },
  { id: "IL", value: 0 },
  { id: "BT", value: 0 },
  { id: "CI", value: 0 },
  { id: "AG", value: 0 },
  { id: "SC", value: 0 },
  { id: "ER", value: 0 },
  { id: "KR", value: 0 },
  { id: "DJ", value: 0 },
  { id: "NA", value: 0 },
  { id: "NZ", value: 0 },
  { id: "CG", value: 0 },
  { id: "MX", value: 0 },
  { id: "PT", value: 0 },
  { id: "SR", value: 0 },
  { id: "VN", value: 0 },
  { id: "BL", value: 0 },
  { id: "SL", value: 0 },
  { id: "RE", value: 0 },
  { id: "AQ", value: 0 },
  { id: "CZ", value: 0 },
  { id: "DK", value: 0 },
  { id: "GG", value: 0 },
  { id: "MF", value: 0 },
  { id: "GS", value: 0 },
  { id: "JM", value: 0 },
  { id: "VE", value: 0 },
  { id: "NO", value: 0 },
  { id: "PA", value: 0 },
  { id: "KW", value: 0 },
  { id: "MT", value: 0 },
  { id: "GI", value: 0 },
  { id: "TJ", value: 0 },
  { id: "FI", value: 0 },
  { id: "MR", value: 0 },
  { id: "NC", value: 0 },
  { id: "PR", value: 0 },
  { id: "NI", value: 0 },
  { id: "UA", value: 0 },
  { id: "TD", value: 0 },
  { id: "AL", value: 0 },
  { id: "WS", value: 0 },
  { id: "HU", value: 0 },
  { id: "GR", value: 0 },
  { id: "TO", value: 0 },
  { id: "GW", value: 0 },
  { id: "CX", value: 0 },
  { id: "IQ", value: 0 },
  { id: "LS", value: 0 },
  { id: "MN", value: 0 },
  { id: "CR", value: 0 },
  { id: "VC", value: 0 },
  { id: "TV", value: 0 },
  { id: "US", value: 0 },
  { id: "MK", value: 0 },
  { id: "GQ", value: 0 },
  { id: "PK", value: 0 },
  { id: "SH", value: 0 },
  { id: "QA", value: 0 },
  { id: "VU", value: 0 },
  { id: "TZ", value: 0 },
  { id: "GF", value: 0 },
  { id: "CD", value: 0 },
  { id: "AO", value: 0 },
  { id: "CC", value: 0 },
  { id: "LC", value: 0 },
  { id: "SS", value: 0 },
  { id: "SB", value: 0 },
  { id: "YE", value: 0 },
  { id: "ET", value: 0 },
  { id: "SO", value: 0 },
  { id: "KG", value: 0 },
  { id: "FR", value: 0 },
  { id: "TW", value: 0 },
  { id: "KH", value: 0 },
  { id: "IN", value: 0 },
  { id: "SD", value: 0 },
  { id: "CU", value: 0 },
  { id: "GM", value: 0 },
  { id: "TK", value: 0 },
  { id: "ID", value: 0 },
  { id: "KE", value: 0 },
  { id: "MM", value: 0 },
  { id: "ST", value: 0 },
  { id: "EC", value: 0 },
  { id: "CL", value: 0 },
  { id: "TH", value: 0 },
  { id: "IS", value: 0 },
  { id: "BO", value: 0 },
  { id: "NF", value: 0 },
  { id: "BG", value: 0 },
  { id: "SI", value: 0 },
  { id: "HT", value: 0 },
  { id: "SG", value: 0 },
  { id: "PF", value: 0 },
  { id: "ZW", value: 0 },
  { id: "CO", value: 0 },
  { id: "EE", value: 0 },
  { id: "NL", value: 0 },
  { id: "NG", value: 0 },
  { id: "SK", value: 0 },
  { id: "IO", value: 0 },
  { id: "SV", value: 0 },
  { id: "RS", value: 0 },
  { id: "RW", value: 0 },
  { id: "SN", value: 0 },
  { id: "AE", value: 0 },
  { id: "IR", value: 0 },
  { id: "TR", value: 0 },
  { id: "TM", value: 0 },
  { id: "BY", value: 0 },
  { id: "SE", value: 0 },
  { id: "DM", value: 0 },
  { id: "LA", value: 0 },
  { id: "BA", value: 0 },
  { id: "MQ", value: 0 },
  { id: "GP", value: 0 },
  { id: "MH", value: 0 },
  { id: "DO", value: 0 },
  { id: "GH", value: 0 },
  { id: "AU", value: 0 },
  { id: "PL", value: 0 },
  { id: "DE", value: 0 },
  { id: "BV", value: 0 },
  { id: "TF", value: 0 },
  { id: "TG", value: 0 },
  { id: "LB", value: 0 },
  { id: "FM", value: 0 },
  { id: "BB", value: 0 },
  { id: "AT", value: 0 },
  { id: "UZ", value: 0 },
  { id: "EG", value: 0 },
  { id: "HR", value: 0 },
  { id: "PE", value: 0 },
  { id: "HK", value: 0 },
  { id: "MD", value: 0 },
  { id: "MG", value: 0 },
  { id: "AZ", value: 0 },
  { id: "SX", value: 0 },
  { id: "SA", value: 0 },
  { id: "PH", value: 0 },
  { id: "LK", value: 0 },
  { id: "LT", value: 0 },
  { id: "IM", value: 0 },
  { id: "JP", value: 0 },
  { id: "PW", value: 0 },
  { id: "CM", value: 0 },
  { id: "AX", value: 0 },
  { id: "KP", value: 0 },
  { id: "PS", value: 0 },
  { id: "TN", value: 0 },
  { id: "SZ", value: 0 },
  { id: "CF", value: 0 },
  { id: "HM", value: 0 },
  { id: "AW", value: 0 },
  { id: "LY", value: 0 },
  { id: "SY", value: 0 },
  { id: "DZ", value: 0 },
  { id: "HN", value: 0 },
  { id: "BD", value: 0 },
  { id: "WF", value: 0 },
  { id: "FK", value: 0 },
  { id: "CW", value: 0 },
  { id: "MV", value: 0 },
  { id: "MY", value: 0 },
  { id: "TT", value: 0 },
  { id: "BW", value: 0 },
  { id: "FO", value: 0 },
  { id: "ZM", value: 0 },
  { id: "MO", value: 0 },
  { id: "CY", value: 0 },
  { id: "AM", value: 0 },
  { id: "UY", value: 0 },
  { id: "GE", value: 0 },
  { id: "BJ", value: 0 },
  { id: "AD", value: 0 },
  { id: "GT", value: 0 },
  { id: "UM", value: 0 },
  { id: "YT", value: 0 },
  { id: "BI", value: 0 },
  { id: "CV", value: 0 },
  { id: "BE", value: 0 },
  { id: "JO", value: 0 },
  { id: "BR", value: 0 },
  { id: "VA", value: 0 },
  { id: "TL", value: 0 },
  { id: "RO", value: 0 },
  { id: "EH", value: 0 },
  { id: "AS", value: 0 },
  { id: "BF", value: 0 },
  { id: "MZ", value: 0 },
  { id: "PY", value: 0 },
  { id: "BH", value: 0 },
  { id: "SJ", value: 0 },
  { id: "IT", value: 0 },
  { id: "BN", value: 0 },
  { id: "FJ", value: 0 },
  { id: "AF", value: 0 },
  { id: "NE", value: 0 },
  { id: "UG", value: 0 },
  { id: "GL", value: 0 },
  { id: "CK", value: 0 },
  { id: "KI", value: 0 },
  { id: "LV", value: 0 },
  { id: "NU", value: 0 },
  { id: "MU", value: 0 },
  { id: "AI", value: 0 },
  { id: "CH", value: 0 },
  { id: "NP", value: 0 },
  { id: "VI", value: 0 },
  { id: "LR", value: 0 },
  { id: "GN", value: 0 },
  { id: "RU", value: 0 },
  { id: "ML", value: 0 },
  { id: "GY", value: 0 },
  { id: "KN", value: 0 },
  { id: "KM", value: 0 },
  { id: "KY", value: 0 },
  { id: "BZ", value: 0 },
  { id: "MA", value: 0 },
  { id: "AR", value: 0 },
  { id: "KZ", value: 0 },
  { id: "LI", value: 0 },
  { id: "MC", value: 0 },
  { id: "ME", value: 0 },
  { id: "OM", value: 0 },
  { id: "GA", value: 0 },
  { id: "BS", value: 0 },
  { id: "PN", value: 0 },
  { id: "MW", value: 0 },
  { id: "CN", value: 0 },
  { id: "BM", value: 0 },
] as any;
