export enum TypeContentMarker {
  CONTENT_FACILITY_RENEWABLE = 0,
  CONTENT_DETAIL_REC_ID = 1,
  CONTENT_ASSET_BUY_REC = 2,
  CONTENT_ASSET_DETAIL = 3,
}
const contentMarkerFacilityRenewable = (itemFacility: any) => {
  const contentString =
    '<div id="content">' +
    '<div id="siteNotice">' +
    "</div>" +
    '<div id="bodyContent">' +
    `<span class="font-bold"><b>${itemFacility.FacilityName}</b></span>` +
    `<p class="font-medium">${itemFacility.CountryName} </p>` +
    `<p  class="font-bold leading-3 text-gray-secondary">Currently at </p>` +
    `<p class="font-semibold text-green-500">${itemFacility.RenewablePerformance?.toFixed(
      2
    )}</p>` +
    `<p class="font-semibold text-gray-secondary">For year ${new Date().getFullYear()}</p>` +
    "</div>" +
    "</div>";
  return contentString;
};
const contentMarkerDetailRecId = (recDetail: any) => {
  const contentString =
    '<div id="content">' +
    '<div id="siteNotice">' +
    "</div>" +
    '<div id="bodyContent">' +
    `<p class="font-bold">REC ID</p>` +
    `<span class="font-medium">${recDetail?.RecId}</span>` +
    `<p class="font-medium">Type: Solar</p>` +
    "</div>" +
    "</div>";
  return contentString;
};
const contentMarkerBuyREc = (assetDetail: any) => {
  const contentString =
    '<div id="content">' +
    '<div id="siteNotice">' +
    "</div>" +
    '<div id="bodyContent">' +
    `<p class="font-bold">${assetDetail.AssetName}</p>` +
    `<span class="font-medium">Price: US$ ${assetDetail.SellOrderItemDetails[0].UnitPrice.toFixed(
      2
    )}/MWh</span>` +
    `<p class="font-medium">Type: ${assetDetail.EnergyValue}</p>` +
    "</div>" +
    "</div>";
  return contentString;
};

const contentMarkerAsset = (assetDetail: any) => {
  const contentString =
    '<div id="content">' +
    '<div id="siteNotice">' +
    "</div>" +
    '<div id="bodyContent">' +
    `<p class="font-bold">Total No Of Recs: ${
      assetDetail.TotalRec
    } RECs Retired For ${new Date().getFullYear()}  </p>` +
    `<p>Asset Name: ${assetDetail?.AssetName}</p>` +
    `<p>Type: ${assetDetail.Type || "N/A"}</p>` +
    `<p>Generation Time/ Vintage: ${assetDetail.VinTage}</p>` +
    "</div>" +
    "</div>";
  return contentString;
};
export const getContentMarker = (type: TypeContentMarker, item: any) => {
  switch (type) {
    case TypeContentMarker.CONTENT_FACILITY_RENEWABLE:
      return contentMarkerFacilityRenewable(item);
    case TypeContentMarker.CONTENT_ASSET_BUY_REC:
      return contentMarkerBuyREc(item);
    case TypeContentMarker.CONTENT_ASSET_DETAIL:
      return contentMarkerAsset(item);
    default:
      return contentMarkerDetailRecId(item);
  }
};
